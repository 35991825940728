* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

*::-webkit-scrollbar {
  width: 6px;
}

*::-webkit-scrollbar-track {
  background: #f6f8fa 0% 0% no-repeat padding-box;
  border-radius: 4px;
}

*::-webkit-scrollbar-thumb {
  background: #d0d5dd 0% 0% no-repeat padding-box;
  border-radius: 4px;
}

html {
  height: 100%;
  min-width: 320px;

  font-size: 16px;
}

@media (max-width: 991px) {
  html {
    font-size: 14px;
  }
}

body {
  width: 100%;
  /* height: 100%; */
  min-height: 100%;

  margin: 0;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

#root {
  /* width: 100vw; */
  /* height: 100vh; */

  width: 100%;
  /* height: 1024px; */
  height: 100dvh;
  /* min-height: 100%; */

  /* min-width: 1024px; */
  /* min-height: 768px; */
}

.form-check-input:checked {
  background-color: #444ce7;
  border-color: #444ce7;
}

@supports (-webkit-overflow-scrolling: touch) {
  input {
    font-size: 16px;
  }
}
